body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-image: url(./images/bg.jpg); /* https://unsplash.com/photos/VxaZjhobxFw */
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (max-width: 640px) {
  .section {
    padding: 0 8% !important;
    font-size: calc(50px + 2vw) !important;
  }
}

/**
 * components/LoginForm.js
 */

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form .row {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.login-form label {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
}

.login-form input {
  width: 100%;
  padding: 12px 10px;
  font-size: 14px;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #e6ecef;
  font-family: monospace;
}

/**
 * card
 */

.card {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  min-height: 150px;
  padding: 20px;
  border-radius: 4px;
  margin: 20px;
  position: fixed;
  top: calc(50% - 200px);
  right: 2vw;
}

.card-heading {
  margin: 0 0 6px 0;
  color: white;
  font-size: 25px;
}

.card-btn {
  background: rgba(255, 255, 255, 0.8);
  color: #444444;
  border: none;
  padding: 8px 20px;
  border-radius: 2px;
  font-weight: 500;
  font-size: inherit;
  margin-top: 16px;
  cursor: pointer;
}

.card-link {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

.card-footer {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 20px;
  text-align: center;
}

.card-footer-cta {
  display: block;
  align-self: center;
}

.card-footer-credits {
  font-size: 10px;
  margin-top: 10px;
}

.card-footer-credits .card-link {
  font-size: inherit;
}

.card-error {
  color: cadetblue;
  margin: 0 0 12px 0;
}

/**
 * components/Status.js
 */

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  font-size: 0;
  margin: 0 17px 2px 6px;
  display: inline-block;
}

.status-item {
  margin-bottom: 8px;
  padding-top: 8px;
}

.status-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

/**
 * components/Loading.js
 */

.loading-container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border-radius: 50%;
  border: 8px solid rgba(113, 211, 211, 0.5);
  border-top: 8px solid rgb(113, 211, 211);
  height: 40px;
  width: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
